import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { ImagePass } from '../../Image/ImagePass';
import './PostListItem.scss'
import { dateI18n } from "@wordpress/date";

export default props => {
  const { dateFormat, post: {path, featured_media, title, excerpt, date } } = props;

  const dateFormatted = date && dateFormat && dateI18n(dateFormat, date);

  console.log(dateFormat)
  return (
    <GatsbyLink
      className="post-list__item"
      to={path}
    >
      <div className="post-list__item__image">
        <ImagePass src={featured_media} />
      </div>
      <div className="post-list__item__info">
        <span className="eyebrow">
          {dateFormatted}
        </span>
        <h3 className="post-list__item__heading" dangerouslySetInnerHTML={{ __html: title}} />
        {excerpt && (
          <RenderContent content={excerpt} />
        )}
      </div>
    </GatsbyLink>
  )
}
